import "./Home.css";
import React from "react";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import pearl from "../images/pearl.png";

const Home = () => {
  const container = useRef();
  const q = gsap.utils.selector(container);
  useEffect(() => {
    let tl = gsap.timeline();

    tl.from(q(".card"), {
      stagger: 0.2,
      opacity: 0,
      x: -20,
    });

    tl.from(q(".avatar img"), {
      stagger: 0.2,
      opacity: 0,
      y: 20,
    });
    tl.from(q(".profile-name"), {
      stagger: 0.2,
      opacity: 0,
      y: 20,
    });
    tl.from(q(".profile-role"), {
      stagger: 0.2,
      opacity: 0,
      y: 20,
    });

    tl.from(q(".social-buttons"), {
      stagger: 0.2,
      opacity: 0,
    });
  }, [q]);
  return (
    <div ref={container} className="container darkContainer">
      <div className="card darkCard">
        <div className="card-header">
          <div className="avatar">
            <img src={pearl} alt="user" />
          </div>
          <div className="profile-name">
            <h1>Muthusamy K</h1>
          </div>
          <div className="profile-role">JavaScript Developer</div>
        </div>

        <div className="card-footer">
          <div className="social-buttons">
            <a
              href="https://github.com/KMuthusamyms"
              rel="noreferrer"
              target="_blank"
              data-tip="Github"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/muthusamyms/"
              rel="noreferrer"
              target="_blank"
              data-tip="LinkedIn"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.instagram.com/muthu.karthick/"
              rel="noreferrer"
              target="_blank"
              data-tip="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              data-tip="Portfolio"
              href="https://kmuthusamyms.github.io/portfolio/"
            >
              <i className="far fa-id-badge"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
