import "./App.css";
import React from "react";
import AppRoutes from "./components/AppRoutes";
import ReactTooltip from "react-tooltip";

const App = () => {
  return (
    <>
      <AppRoutes />
      <ReactTooltip effect='solid' />
    </>
  );
};

export default App;
